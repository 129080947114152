.containerFluid2,
.containerFluid,
.container {

}

.out {
	//min-width: 1400px;

	//@include r($xs-breakpoint) {
	//    min-width: 100%;
	//}
}



.containerFluid,
.container {
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.containerFluid {
	padding: 0 63px;
}

.i-content {
	max-width: 700px;
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.containerFluid2 {
	max-width: 1382px;
	margin: 0 auto;
	position: relative;
}
.containerFluid3 {
	max-width: 1440px;
	margin: 0 auto;
	position: relative;
}

.container {
	//max-width: 1138px;
	max-width: 1208px;
}

.hiddenMobile {
	@include r($mob) {
		display: none;
	}
}

.hiddenDesktop {

	@include rmin($mob) {
		display: none;
	}
}

.bgWhite {
	background-color: #fff;
}

.colorPrimary {
	color: $primary-color;
}

.marginMinusPaddingContainer {
	margin: 0 -50px;
}

.marginMinusLeftPaddingContainer {
	margin-left: -50px;
}

.marginMinusRightPaddingContainer {
	margin-right: -50px;
}

.spaceBetween {
	justify-content: space-between;
}


.alignCenter {
	align-items: center;
}
