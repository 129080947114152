textarea,
input {
	border: 0;
	border-radius: 0;
	background-color: #fff;
	font-family: inherit;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

select {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
}

select::-ms-expand {
	display: none !important;
}

textarea {
	resize: none;
}


.textarea,
.input {

}

.textarea {
	height: 113px;
	border: 5px solid $black;
	width: 100%;
	font-size: 24px;
	padding-left: 31px;
	padding-top: 16px;
}

.input {
	height: 66px;
	border: 5px solid $black;
	width: 100%;
	font-size: 24px;
	padding-left: 31px;

	&::placeholder {
		color: #8B8B8B;
	}

	&.input--sm {

	}

	&.input--xs {

	}
}

.textarea {

}
