.card--good {

	.card__photo {
		border: 3px solid $black;
		position: relative;
	}

	.card__slider {

		img {
			width: 100%;
			pointer-events: none;
		}
	}

	.card__body {
		padding: 33px 40px;
		border: 3px solid;
		border-color: transparent $black $black $black;
		margin-top: -3px;
		background-color: #fff;
	}

	.card__title {
		font-family: $font-secondary;
		font-size: 18px;
		margin-bottom: 22px;
	}

	.card__subtitle {
		font-weight: 300;
		font-size: 13px;
		line-height: 17px;
		max-width: 611px;
	}

	.card__bottom {
		display: flex;
		flex-flow: wrap;
		border: 3px solid;
		border-color: transparent $black $black $black;
		margin-top: -3px;

		.card__nav {
			width: percentage(391/834);
			background-color: #C4C4C4;
		}

		.card__button {
			border-left: 3px solid $black;
			width: percentage(443/834);
		}
	}

	.card__nav {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 42px 0 27px;
		height: 50px;


		.swiper-controls {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 163px;
		}

		.swiper-button {
			position: relative;
			background-image: none;
			margin-top: 0;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			width: auto;
			height: auto;
		}

		.swiper-pagination {
			position: relative;
			bottom: 0;
			width: auto;
			font-size: 14px;

			&:before {
				content: attr(data-prefix) ' ';
			}
		}
	}
}
