.nav--pages {

	.menu {


		.menu__item:not(:first-child) {
			margin-top: 33px;
		}

		a {
			font-family: $font-secondary;
			font-size: 18px;
			line-height: 1;
			color: #C4C4C4;
			text-transform: uppercase;

			&:hover,
			&.is-active {
				color: #000;
			}

			&.is-active {
				pointer-events: none;
			}
		}
	}
}
