.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;


	.containerFluid {
		padding-top: 23px;

		.header__logo {
			position: relative;
			left: -5px;
		}
	}

	.container {
		padding-top: 23px;
		display: flex;
		justify-content: space-between;

		.header__logo {
			position: relative;
			left: -3px;
		}
	}

	.header__burger {
		margin-top: -23px;
	}
}


.btn--burger {
	padding: 43px 49px 43px 45px;
	background-color: #000;
	color: #fff;
	transition: none;
	height: 122px;


	&:hover {
		color: #fff;
	}

	&.is-active {
		background-color: #fff;
		color: #000;

		.bar {
			background-color: #000;
			margin-top: 0 !important;

			&:nth-child(2) {
				display: none;
			}

			&:nth-child(1) {
				transform: rotate(45deg) translate(0, 2px);
			}

			&:nth-child(3) {
				transform: rotate(-45deg) translate(1px, -2.5px);
			}
		}

		.dropdown {
			opacity: 1;
			visibility: visible;
		}
	}

	.btn__label {
		display: flex;
		align-items: center;
	}

	.btn__title {
		font-size: 18px;
		text-transform: uppercase;
		font-family: $font-secondary;
		margin-right: 40px;
	}

	.bar {
		height: 5px;
		width: 44px;
		background-color: #fff;
		display: block;
		transition: .3s all;

		&:not(:first-child) {
			margin-top: 10.5px;
		}
	}

	.dropdown {
		background-color: #fff;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: -25px;
		opacity: 0;
		visibility: hidden;

		.dropdown__list {
			text-align: left;
			padding: 0 39px 30px;

			a {
				font-size: 24px;
				line-height: 200%;
				text-transform: uppercase;
				color: #A9A9A9;
				display: block;

				&:hover {
					color: #000;
				}
			}
		}
	}

}
