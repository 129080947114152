@font-face {
	font-family: 'DrukTextWideCyTT';
	src: url('../fonts/DrukTextWideCyTT-Heavy.eot?#iefix') format('embedded-opentype'),
	url('../fonts/DrukTextWideCyTT-Heavy.woff') format('woff'),
	url('../fonts/DrukTextWideCyTT-Heavy.ttf') format('truetype'),
	url('../fonts/DrukTextWideCyTT-Heavy.svg#DrukTextWideCyTT-Heavy') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Aeroport';
	src: url('../fonts/Aeroport.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Aeroport.otf') format('opentype'),
	url('../fonts/Aeroport.woff') format('woff'),
	url('../fonts/Aeroport.ttf') format('truetype'),
	url('../fonts/Aeroport.svg#Aeroport') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Aeroport';
	src: url('../fonts/Aeroport-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Aeroport-Bold.otf') format('opentype'),
	url('../fonts/Aeroport-Bold.woff') format('woff'),
	url('../fonts/Aeroport-Bold.ttf') format('truetype'),
	url('../fonts/Aeroport-Bold.svg#Aeroport-Bold') format('svg');
	font-weight: normals;
	font-style: normal;
}


.title {


	&.title--24 {
		font-size: 24px;
		line-height: 150%;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&.title--36 {
		font-size: 36px;
		line-height: 150%;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&.title--48 {
		font-size: 48px;
		line-height: 61px;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&.title--64 {
		font-size: 64px;
		line-height: 81px;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&.title--96 {
		font-size: 96px;
		line-height: 122px;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	&.title--stroke {
		color: transparent;
		-webkit-text-stroke: 2px $gray;


		&.colorWhite {
			color: transparent;
			-webkit-text-stroke: 2px $white;
		}

		&.colorBlack {
			color: transparent;
			-webkit-text-stroke: 1px $black;
		}
	}

}


.colorGray {
	color: $gray;
}

.colorWhite {
	color: $white;
}

.textCenter {
	text-align: center;
}

.backgroundGray {
	background-color: #EDEDED;
}
