.modal {
	width: 100%;
	min-height: 100%;
	display: none;
	padding: 44px 0;
	background-color: transparent;
	pointer-events: none;
	overflow-x: hidden;
	overflow-y: scroll;
	text-align: center;

	&.is-test {
		position: fixed;
		display: block;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		background-color: rgba(#000, .4);
	}

	.modal__inner {
		pointer-events: all;
		display: inline-block;
		text-align: left;
	}

	.fancybox-close-small {
		display: none;
	}
}


.fancybox-stage {
	transform: none;
	direction: inherit;
}

.fancybox-slide {
	transition-property: none;
	direction: inherit;

	&:before {
		content: none;
	}
}
.fancybox-container {
	transform: none;
	touch-action: auto;
}

.modal__close {
	width: 66px;
	height: 66px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000;
}

#callback {

	@include rh(650) {
		//padding: 0;
		//top: 50%;
		//transform: translateY(-50%);
	}
}

.fancybox-slide {
	padding: 0;
}
