.section--projects {
	margin-top: 155px;
	padding-bottom: 139px;
}


.projects {
	display: flex;
	flex-flow: wrap;
	margin-top: 125px;
	padding: 0 80px;
	max-width: 1440px;
	margin-left: auto;
	margin-right: auto;

	.projectsItem {
		width: 50%;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		margin-bottom: 100px;
	}
}

.projectsItem {
	text-align: center;
	text-transform: uppercase;
	font-size: 24px;
	line-height: 150%;


	.projectsItem__icon {
		min-height: 233px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.projectsItem__title {
		margin-top: 34px;
		min-height: 108px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.projectsLink {
	text-align: center;
	margin-top: 31px;

	a {
		font-family: $font-secondary;
		font-size: 36px;
		text-transform: uppercase;
		position: relative;
		padding-bottom: 5px;


		&:before {
			content: '';
			position: absolute;
			bottom:-3px;
			left: 0;
			right: 0;
			height: 2px;
			background-color: #000;
		}

		&:hover {
			background-color: #8B8B8B;
			color: #fff;

			&:before {
				opacity: 0;
			}
		}
	}
}
