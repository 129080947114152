.section--about {
	padding-top: 124px;
}

.about {
	display: flex;
	justify-content: space-between;

	.about__left {
		width: percentage(345/1196);
	}

	.about__right {
		width: percentage(709/1196);
		margin-top: -3px;
	}
}


.aboutListImages {
	margin: 57px 0;
	display: flex;
	flex-flow: wrap;

	li {
		width: 50%;
		padding: 15px 0;

		&:nth-child(even) {
			border-left: 1px solid #A9A9A9;
			text-align: right;
		}
	}
}
