.footer {
	//position: relative;

	.footer__logo {
		text-align: center;
		pointer-events: none;
	}

	.copyright {
		position: absolute;
		bottom: 114px;
		left: 26px;
	}
}

.copyright {
	font-size: 18px;
	text-transform: uppercase;
}
