.section--digital {
	padding-top: 95px;
	margin-bottom: -166px;
	position: relative;
	z-index: 1;
	text-align: right;

	.digital {
		display: inline-block;
		text-align: left;
	}
}

.digital {
	position: relative;

	.digital__inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 152px 138px;
	}

	.digital__top {
		position: relative;
		display: flex;

		.digital__icon {
			min-width: 181px;
		}

		.digital__description {
			padding-left: 72px;
			padding-top: 11px;


			.digital__title {
				line-height: 1;
			}

			.digital__text {
				margin-top: 45px;
				max-width: 520px;

				p {
					font-size: 16px;
					line-height: 24px;
					margin: 0;
				}
			}
		}
	}

	.digital__frame {
		margin: 28px -8px;
	}

	.digital__download {
		max-width: 464px;
		margin-left: auto;
		margin-top: 45px;
		margin-right: 72px;

		.digital__appstore {
			padding-left: 34px;
		}
	}

	.digital__info {
		margin-top: 55px;
		font-weight: 300;

		p {
			font-size: 16px;
			line-height: 24px;

			a {
				text-decoration: underline;
				color: #000;

				&:hover {
					color: rgba(#000, .5);
				}
			}
		}

		p:not(:first-child) {
			margin-top: 20px;
		}
	}
}
