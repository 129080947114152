.section--library {
	padding-top: 125px;
	padding-bottom: 260px;
}

.quadBg {
	background-image: url('../img/bg_library_page.png');
	background-color: #EDEDED;
}


.library {
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;

	.library__left {
		width: percentage(300/1165);
	}

	.library__right {
		width: percentage(840/1165);

		.grid {
			margin-top: -17px;

			.grid__item {
				margin-bottom: 106px;
				padding: 0 8px;
			}
		}
	}
}

.card--book {
	text-align: center;
	position: relative;

	.card__link {
		@include coverdiv();
	}

	.card__author {
		font-size: 12px;
		line-height: 18px;
		color: #8B8B8B;
		margin-top: 16px;
	}

	.card__title {
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;
		margin-top: 7px;
	}
}

.linksBox {
	max-width: 550px;
	margin-top: 60px;
	padding-left: 35px;

	.linksBox__title {
		text-transform: initial;
	}

	.linksBox__body {

		&:not(:first-child) {
			margin-top: 60px;
		}
	}

	.linksBox__row:not(:first-child) {
		margin-top: 90px;
	}

}

.linksBoxItem {
	font-size: 12px;
	line-height: 18px;
	color: #A9A9A9;
	font-weight: 300;
	display: block;

	&:not(:first-child) {
		margin-top: 32px;
	}

	&__title {
		font-weight: normal;
		font-size: 14px;
		line-height: 21px;
		color: #000000;
		margin-bottom: 10px;
	}
}
