.section--interesting {

	.interesting {
		margin-top: 63px;
	}
}

.interesting {
	display: flex;
	flex-flow: wrap;
	margin: 0 -11.5px;

	.interesting__item {
		margin: 0 11.5px;
		width: calc(50% - 23px);
		border: 5px solid #000000;
		display: flex;
		align-items: center;
		padding: 35px 33px;
		font-size: 18px;
		line-height: 27px;
		font-weight: 300;
		color: rgba(0, 0, 0, 0.5);


		.interesting__icon {
			width: 150px;
			height: 150px;
		}

		.interesting__text {
			padding-left: 58px;
			padding-right: 20px;
		}
	}
}
