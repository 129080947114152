.orderBox {
	background-color: #fff;
	border: 5px solid $black;
	width: 671px;
	position: relative;
	margin: 0 auto;
	padding: 84px 89px;

	.modal__close {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 57px;
		margin-right: 88px;
	}
}

.goodItem {
	display: flex;
	align-items: center;

	.goodItem__photo {
		width: 192px;
		min-width: 192px;
		height: 192px;
		border: 5px solid $black;
		margin-right: 61px;
	}

	.goodItem__title {
		font-size: 18px;
		color: #8B8B8B;
		margin-bottom: 3px;
	}

	.goodItem__subtitle {
		font-size: 18px;
		font-family: $font-secondary;
		margin-bottom: 22px;
	}
}


.quantity {

	.quantity__title {
		font-size: 18px;
		color: #8B8B8B;
		margin-bottom: 11px;
	}

	.quantity__inner {
		display: flex;
		align-items: center;
		width: 178px;
	}

	svg {
		vertical-align: middle;
	}

	.quantity__value {
		width: 50px;
		height: 50px;
		border: 5px solid $black;
		background-color: #fff;
		text-align: center;
		font-family: $font-secondary;
		font-size: 24px;
		margin: 0 14px;
	}
}

.orderBox__form {
	margin-top: 43px;

	.form__field {
		margin-top: 34px;
	}

	.form__button {
		margin-top: 34px;
	}
}

.orderBoxHead {
	color: #000;
	margin-top: -30px;

	.orderBoxHead__slogan {
		font-size: 24px;
		line-height: 150%;
		color: #000000;
		margin-bottom: 10px;
	}

	.orderBoxHead__title {
		font-size: 36px;
		line-height: 1;
		font-family: $font-secondary;
	}
}
