h1, h2, h3, h4 {
	margin: 0;
	font-weight: normal;
	font-family: $font-secondary;
	text-transform: uppercase;
}

h1 {
}

h2 {
	font-size: 24px;
	line-height: 36px;
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
	margin: 0;
	font-size: 18px;
	line-height: 27px;
	color: rgba(0, 0, 0, 0.5);
}

article {

	h2 + p {
		margin-top: 61px;
	}

	p {
		font-weight: 300;
		margin: 27px 0;
	}


	p + h2 {
		margin-top: 86px;
	}
}
