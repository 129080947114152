.section--forYou {
	margin-top: 146px;
}


.forYou {
	display: flex;
	flex-flow: wrap;
	justify-content: space-between;

	.forYou__left {
		width: percentage(300/1165);
	}

	.forYou__right {
		width: percentage(805/1165);
		margin-top: -17px;
	}
}

.grid--goods {

	.grid__item:not(:first-child) {
		margin-top: 63px;
	}
}


.booksBox {

	.booksBox__title {
		font-size: 48px;
		line-height: 1;
		font-family: $font-secondary;
		margin-bottom: 70px;
	}

	.grid--books {
		margin: 0 -17.5px;

		.grid__item {
			padding: 0 17.5px;
			margin-bottom: 112px;
		}
	}
}

.card--book2 {

	.card__photo {
		margin-bottom: 35px;
	}

	.card__title {
		color: #8B8B8B;
		font-size: 24px;
		line-height: 1;
		text-transform: uppercase;
	}

	.card__subtitle {
		font-size: 16px;
		line-height: 19px;
		color: rgba(0, 0, 0, 0.5);
		margin-top: 27px;
	}

	.card__bottom {
		margin-top: 20px;
	}
}

.winBox {
	padding-bottom: 100px;


	.winBox__title {
		font-size: 48px;
		font-family: $font-secondary;
		text-transform: uppercase;
		margin-bottom: 90px;
	}

	.winBoxInfo {
		color: rgba(0, 0, 0, 0.5);
		font-size: 16px;
		line-height: 24px;
		font-weight: 300;

		&__title {
			font-weight: normal;
			font-size: 24px;
			line-height: 48px;
			text-transform: uppercase;
			color: #8B8B8B;
			margin-bottom: 5px;
		}

		&__button {
			margin-top: 56px;

			.btn {
				max-width: 340px;
			}
		}
	}

	.winBoxInfo__button {
		&.is-hidden {
			display: none;
		}
	}
}

.winBox__hidden {
	display: none;

	&.is-active {
		display: block;
	}
}

.quest {

	.questBox {
		background-color: #fff;
		margin: -15px -48px;
		padding: 48px 48px 75px;

		&__title {
			font-family: $font-secondary;
			font-size: 24px;
			text-transform: uppercase;
			margin-bottom: 44px;
		}

		&__text {

			p {
				font-size: 16px;
				line-height: 24px;
				font-weight: 300;


				&:not(:first-child) {
					margin-top: 24px;
				}
			}
		}

		&__button {
			max-width: 340px;
			margin-top: 53px;
		}
	}
}

.questPrize {
	.questPrize__title {
		font-size: 24px;
		line-height: 1;
		font-family: $font-secondary;
		text-transform: uppercase;
	}
}

.questPrize {
	margin-top: 135px;
	margin-right: -10px;


	.questPrize__body {
		margin-top: 50px;
		display: flex;
		flex-flow: wrap;
	}

	.questPrize__item {
		width: 398px;

		&:nth-child(even) {
			position: relative;
			margin-left: 45px;

			&:before {
				content: '+';
				font-size: 48px;
				font-weight: 300;
				position: absolute;
				top: 154px;
				left: -36px;
			}
		}
	}


	.questPrize__photo {
		display: inline-block;
		border: 3px solid $black;
	}

	.questPrize__text {
		font-size: 18px;
		line-height: 27px;
		text-transform: uppercase;
		margin-top: 49px;
	}
}

.questRules {
	margin-top: 77px;
	.questRules__title {
		font-size: 24px;
		line-height: 1;
		font-family: $font-secondary;
		text-transform: uppercase;
	}

	.questRules__body {
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.5);
		font-weight: 300;
		margin-top: 50px;
	}
}
