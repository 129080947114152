.nav--primary {


	.menu {
		display: flex;
		flex-flow: wrap;


		.menu__item {
			margin-bottom: 29px;

			&:nth-child(even) {
				position: relative;

				&:before {
					content: '|';
					font-family: $font-secondary;
					line-height: 1;
					color: $gray;
					font-size: 130%;
					display: inline-block;
					margin-left: 13px;
					margin-right: 14px;
				}
			}
		}


		.menu__link {
			font-family: $font-secondary;
			font-size: 24px;
			text-transform: uppercase;
		}
	}
}
