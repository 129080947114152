html {
	font-family: $font-main;
	-webkit-tap-highlight-color: transparent;
	@include font-smoothing();
	color: $text-color;
	font-size: 18px;
	line-height: 24px;
}

.pageWrapper,
html,
body {
	//background-color: #F1F8FD;
}

body {
	background-color: #EDEDED;
}

* {
	outline: none;
	box-sizing: border-box;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

a {
	transition: 0.25s all;
	text-decoration: none;
	color: $black;

	&:hover {
		color: $primary-color;
	}

	&:active {
		opacity: 0.8;
	}
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

input:focus {
	outline: none;
}

::selection {
	color: $white;
	background-color: $primary-color;
}

::-moz-selection {
	color: $white;
	background-color: $primary-color;
}

:root {
	--browser-address-bar: 0px;
	--browser-bar: 0px;
}

.out {
	position: relative;
	overflow: hidden;
	min-width: 1325px;
}

.pageWrapper {
	position: relative;
}

.no-scroll {
	overflow: hidden;
}

.is-fixed {
	position: fixed;
	top: 0;
	left: 0;
}

.section__bg {
	@include cover();
}

.section--vh {
	height: 100vh;

	.section__bg {
		height: 100%;

		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.section--gray {
	background-color: #f5f5f5;
}
