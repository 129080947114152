.section--experts {
	padding-top: 129px;
}

.experts {
	position: relative;
	display: flex;
	flex-flow: wrap;
	margin-top: 100px;

	.expertsItem:nth-child(1) {
		margin-left: rem(40px);
		margin-top: rem(25px);
	}
	.expertsItem:nth-child(2) {
		margin-left: rem(224px);
		margin-top: rem(-2px);
	}
	.expertsItem:nth-child(3) {
		margin-left: auto;
		margin-right: rem(6px);
	}
	.expertsItem:nth-child(4) {
		margin-top: rem(-94px);
		margin-left: rem(315px);
	}
	.expertsItem:nth-child(5) {
		margin-left: rem(113px);
		margin-top: rem(-13px);
	}
	.expertsItem:nth-child(6) {
		margin-left: rem(44px);
		margin-top: rem(-134px);
	}
	.expertsItem:nth-child(7) {
		margin-top: rem(-46px);
		margin-left: rem(261px);
	}
	.expertsItem:nth-child(8) {
		margin-left: auto;
		margin-top: rem(-149px);
		margin-right: rem(7px);
	}
}

.expertsItem {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 100%;
	border: 2px solid #000000;
	padding: rem(50px);

	&:hover {
		.expertsItem__info {
			opacity: 1;
		}
	}


	&.expertsItem--330 {
		width: rem(330px);
		height: rem(330px);
	}

	&.expertsItem--320 {
		width: rem(320px);
		height: rem(320px);
	}
	&.expertsItem--352 {
		width: rem(352px);
		height: rem(352px);
	}
	&.expertsItem--270 {
		width: rem(270px);
		height: rem(270px);
	}
	&.expertsItem--290 {
		width: rem(290px);
		height: rem(290px);
	}
	&.expertsItem--196 {
		width: rem(196px);
		height: rem(196px);
	}
	&.expertsItem--288 {
		width: rem(288px);
		height: rem(288px);
	}

	.expertsItem__preview {
		font-size: rem(18px);
		line-height: rem(25px);
		text-align: center;
		text-transform: uppercase;
		color: rgba(0, 0, 0, 0.5);
	}

	.expertsItem__info {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #8B8B8B;
		opacity: 0;
		border-radius: 100%;
		color: #fff;
		font-size: rem(16px);
		line-height: rem(22px);
		transition: .3s all;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem(20px);
		text-align: center;
	}
}
