.grid {
	display: flex;
	flex-flow: wrap;

	.grid__item:not([class*="columnDesktop-"]) {
		width: 100%;
	}

	.grid__item[class*="columnDesktop-"] {
	}

	+ .gridMore {
		margin-top: 57px;
	}
}


/* GRID SYSTEM */


@mixin grid-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.column#{$size}--#{$i} {
			width: percentage($i / $grid-columns);
		}
	}
}

@mixin grid-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.column#{$size}--#{$i} {
				width: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin padding-right-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.padding-right-#{$size}--#{$i} {
			padding-right: percentage($i / $grid-columns);
		}
	}
}

@mixin padding-left-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.padding-left-#{$size}-#{$i} {
			padding-left: percentage($i / $grid-columns);
		}
	}
}

@mixin padding-right-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.padding-right-#{$size}-#{$i} {
				padding-right: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin padding-left-generator2($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		@include r($sm-breakpoint) {
			.padding-left-#{$size}-#{$i} {
				padding-left: percentage($i / $grid-columns);
			}
		}
	}
}

@mixin margin-right-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.margin-right-#{$size}-#{$i} {
			margin-right: percentage($i / $grid-columns);
		}
	}
}

@mixin margin-left-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.margin-left-#{$size}-#{$i} {
			margin-left: percentage($i / $grid-columns);
		}
	}
}

@include grid-generator(Desktop, 12);

@include grid-generator2(Tablet, 12);

@include padding-left-generator(md, 12);
@include padding-right-generator(md, 12);

@include padding-left-generator2(sm, 8);
@include padding-right-generator2(sm, 8);

@include margin-left-generator(md, 12);
@include margin-right-generator(md, 12);

.grid .grid__item.columnsAuto {
	width: auto;
}

.columnsAuto {
	width: auto;
}
