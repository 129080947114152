.section--general {
	@include cover();
	position: relative;

	&[style*="background"]:before {
		content: '';
		@include coverdiv();
		background-color: #000;
		opacity: .4;
	}


	.section__inner {
		position: relative;
		z-index: 5;
	}
}

.general {
	display: flex;
	flex-flow: column;
	min-height: 100vh;
	justify-content: space-between;
	padding: 190px 0 66px;

	.general__titles {

		.title:not(:first-child) {
			margin-top: 16px;
		}
	}


	.general__bottom {
		max-width: 447px;
		padding-top: 80px;

		.generalLinks:not(:first-child) {
			margin-top: 85px;
		}
	}

}

.generalLinks {

	a {
		color: #A9A9A9;
		font-size: 18px;
		font-weight: 700;
		text-decoration: underline;
	}
}
