.section--words {
	padding: 105px 0 156px;
}

.words {

	.words__row {
		min-width: 100%;
		display: inline-block;
		white-space: nowrap;
		text-align: center;
		font-size: 64px;
		line-height: 81px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);

		&:not(:first-child) {
			margin-top: -4px;
		}

		.words__item {
			display: inline-block;
		}
	}
}


.words__item {
	cursor: default;
	user-select: none;

	.title {
		transition: .3s all;
	}

	&:hover {

		.title--stroke {
			-webkit-text-stroke: 2px #8B8B8B;
			color: #8B8B8B;
		}
	}
}
