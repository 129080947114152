.section--hero {
	@include cover();
	position: relative;
	z-index: 1;
}

.hero {
	min-height: 531px;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	padding-bottom: 84px;
}
