button,
.btn {
	position: relative;
	display: inline-block;
	border-radius: 0;
	border: 0;
	transition: .3s all;
	background-color: transparent;
	text-align: center;
	padding: 0;
	cursor: pointer;
}

.btn--primary {
	background-color: $black;
	color: #fff;
	width: 100%;
	height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #fff;
		color: $black;
	}
}
.btn--primary2 {
	background-color: $black;
	color: #fff;
	width: 100%;
	height: 66px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $font-secondary;
	font-size: 24px;

	&:hover {
		background-color: rgba($black, .8);
		color: #fff;
	}
}
.btn--primary3 {
	background-color: $black;
	color: #fff;
	width: 100%;
	height: 66px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: $font-secondary;
	font-size: 18px;

	&:hover {
		background-color: rgba($black, .8);
		color: #fff;
	}
}

.btn--secondary {

	&:hover {

	}
}

.btn--uppercase {
	text-transform: uppercase;
}
