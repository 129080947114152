.section--study {
	padding-top: 130px;
}

.studyInfo {
	display: flex;
	justify-content: space-between;

	.studyInfo__left {
		width: percentage(300/1194);
	}

	.studyInfo__right {
		width: percentage(710/1194);
	}

	&__title {
		font-family: $font-secondary;
		font-size: 48px;
		text-transform: uppercase;
	}

	&__subtitle {
		font-weight: 300;
		font-size: 24px;
		text-transform: uppercase;
		margin-top: 45px;
	}

	&__text {
		font-weight: 300;
		font-size: 18px;
		line-height: 22px;
		color: rgba(0, 0, 0, 0.5);
		margin-top: 65px;

		p {
			font-size: 18px;
			line-height: 22px;

			&:not(:first-child) {
				margin-top: 23px;
			}
		}
	}

}


.linksMedia {

	&__title {
		font-size: 32px;
		font-family: $font-secondary;
		margin-bottom: 55px;
	}

	&__list {

		li:not(:first-child) {
			margin-top: 10px;
		}

		a {
			font-family: $font-secondary;
			font-size: 14px;
			text-transform: uppercase;
			text-decoration: underline;
		}
	}
}
