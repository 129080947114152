.section--internships {
	padding: 128px 0 86px;

	.internships {
		margin-top: 69px;
	}
}

.internships {
	display: flex;

	.internships__item {
		width: 33.33%;
	}
}

.internships__item {
	font-weight: 300;
	font-size: 14px;
	line-height: 150%;
	color: rgba(0, 0, 0, 0.5);

	.internships__photo {
		min-height: 95px;
		display: flex;
		align-items: center;
		margin-bottom: 45px;
	}
}
