.section--gallery {
	margin-top: 137px;
}

.gallerySlider {

	&:nth-child(2) {
		margin: 21px -60px 0;
	}


	.swiper-slide {
		height: auto;
		width: auto;
		border: 5px solid $black;

		img {
			width: 320px;
			filter: grayscale(1);
			object-fit: cover;
			height: 100%;
			transition: .3s all;

			&:hover {
				filter: grayscale(0);
			}
		}
	}
}
