.section--master {
	margin-top: 167px;
}

.master__title {
	font-size: 48px;
	font-family: $font-secondary;
	text-align: center;
	text-transform: uppercase;
}

.master__list {
	display: flex;
	margin-top: 102px;

	.master__item {
		width: 33.33%;
		padding: 0 30px;
	}
}

.masterSkolkovo {
	text-align: center;
	padding: 40px 0;

	&__logo {
		margin-bottom: 70px;
	}

	&__button {
		margin-top: 70px;

		a {
			font-size: 24px;
			line-height: 43px;
			text-align: center;
			color: #FFFFFF;
			font-weight: 300;
			padding-bottom: 3px;
		}
	}


	&__link {
		margin-top: 13px;

		a {
			font-size: 24px;
			line-height: 180%;
			/* or 43px */
			text-align: center;
			text-decoration-line: underline;
			color: #000000;
			font-weight: 300;
			height: 50px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:hover {
				color: #fff;
				background: #8B8B8B;
				width: 100%;
				text-decoration: none;
			}
		}
	}
}


.masterProgram {
	border: 5px solid transparent;
	text-align: center;
	padding: 72px 0;
	transition: .3s all;
	display: block;

	&:hover {
		border-color: #8B8B8B;

		.masterProgram__link {
			opacity: 1;
		}
	}

	&__title {
		font-family: $font-secondary;
		font-size: 24px;
		line-height: 30px;
		color: #000;
	}

	&__link {
		margin-top: 85px;
		opacity: 0;
		transition: .3s all;


		span {
			font-size: 18px;
			line-height: 25px;
			text-align: center;
			text-decoration-line: underline;
			text-transform: uppercase;
			color: #8B8B8B;
		}
	}
}


.masterSse {
	text-align: center;
	padding: 35px 0;


	&__link {
		margin-top: 75px;

		a {
			font-size: 24px;
			line-height: 180%;
			/* or 43px */
			text-align: center;
			text-decoration-line: underline;
			color: #000000;
			font-weight: 300;
			height: 50px;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			&:hover {
				color: #fff;
				background: #8B8B8B;
				width: 100%;
				text-decoration: none;
			}
		}
	}
}
